import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import JsPDF from "jspdf";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
const prefix = "https://static.wixstatic.com/media/";
const isMobile = window.innerWidth < 768;
export function Products() {
  const [categoryWithProducts, setCategoryWithProducts] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://yonivas0.editorx.io/mitkaplimbackend/_functions/getProductsData"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // If you expect JSON response, use response.json()
        // If you expect other response types, adjust accordingly
        const data = await response.json();
        const categories = data.message.map((category) => ({
          ...category,
          image: category.image.slice(15, category.image.indexOf(".jpg") + 4),
        }));
        setCategoryWithProducts(categories);
        // console.log("category.message", data.message, categories[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const [swiper, setSwiper] = useState();
  const ProductCard = ({ product, index }) => {
    return (
      <div
        className={`w-full mb-3 shadow-2 p-3 flex flex-column ${
          index === selectedProductIndex ? "bg-primary" : "bg-white"
        }`}
        style={{ maxWidth: isMobile ? "" : "300px", minHeight: "6rem" }}
        onClick={(e) => {
          console.log("selected product", product);
          setSelectedProductIndex(index);
          isMobile && setDialogVisible(true);
        }}
      >
        <h3 className="font-medium text-xl h-2rem m-0 w-max">
          {product.title}
        </h3>
        <div className="max-h-3rem">{product?.maxSeats}</div>
      </div>
    );
  };
  const SelectedProductCard = () => {
    const product =
      categoryWithProducts[selectedCategoryIndex].products[
        selectedProductIndex
      ];
    return (
      <div className="w-full h-full align-self-center flex flex-column">
        {!isMobile && <h3 className="text-900 mb-2">{product.title}</h3>}
        <div
          className={`${
            !isMobile && "shadow-1"
          } md:p-3 flex flex-column align-items-center bg-white overflow-y-scroll`}
          style={{ height: "65vh" }}
        >
          <div className="w-11 h-min mb-5 flex flex-column align-items-center text-primary">
            <h2 className="w-full text-right font-normal mt-0 mb-1 md:mt-3 md:mb-3 text-primary">
              מפרט טכני
            </h2>
            <span className="w-full flex flex-column md:flex-row justify-content-between md:gap-2">
              {product.length && (
                <span className="w-full" style={{ minHeight: "2rem" }}>
                  {`אורך: ${product.length / 10} ס"מ (${product.length} מ"מ)`}
                </span>
              )}
              {isMobile && product.maxSeats && (
                <hr className="w-full m-0 border-top-1 border-none border-300 mb-3" />
              )}
              {product.maxSeats && (
                <span
                  className="w-full"
                  style={{ minHeight: "2rem" }}
                >{` ${product.maxSeats}`}</span>
              )}
            </span>
            <hr className="w-full mt-0 border-top-1 border-none border-300 mb-3" />
            <span className="w-full flex flex-column md:flex-row justify-content-between md:gap-2">
              {product.width && (
                <span className="w-full" style={{ minHeight: "2rem" }}>
                  {`רוחב: ${product.width / 10} ס"מ (${product.width} מ"מ)`}
                </span>
              )}
              {isMobile && product.weight && (
                <hr className="w-full m-0 border-top-1 border-none border-300 mb-3" />
              )}
              {product.weight && (
                <span className="w-full" style={{ minHeight: "2rem" }}>
                  {`משקל עצמי: ${product.weight} ק"ג`}
                </span>
              )}
            </span>
            <hr className="w-full mt-0 border-top-1 border-none border-300 mb-3" />
            <span className="w-full flex flex-column md:flex-row justify-content-between md:gap-2">
              {product.height && (
                <span className="w-full flex" style={{ minHeight: "2rem" }}>
                  {`גובה: ${product.height / 10} ס"מ (${product.height} מ"מ)`}
                  {product.heightComment && `, ${product.heightComment}`}
                </span>
              )}
              {isMobile && product.maxWeight && (
                <hr className="w-full m-0 border-top-1 border-none border-300 mb-3" />
              )}
              {product.maxWeight && (
                <span className="w-full" style={{ minHeight: "2rem" }}>
                  {`עומס מרבי: ${product.maxWeight} ק"ג`}
                </span>
              )}
            </span>
            <hr className="w-full mt-0 border-top-1 border-none border-300 mb-3" />
            <span className="w-full flex flex-column md:flex-row justify-content-between md:gap-2">
              {product.thickness && (
                <span className="w-full" style={{ minHeight: "2rem" }}>
                  {`עובי: ${product.thickness / 10} ס"מ (${
                    product.thickness
                  } מ"מ)`}
                </span>
              )}
            </span>
            <hr className="w-full mt-0 border-top-1 border-none border-300 mb-3" />
          </div>
          {product.mediagallery && (
            <div className="w-11 flex flex-column align-items-center justify-content-between md:gap-2">
              {product.mediagallery[0] && (
                <span
                  className="bg-cover bg-no-repeat"
                  style={{
                    width: "70vw ",
                    height: "35vw ",
                    backgroundImage: `url(${
                      prefix + product.mediagallery[0].slug
                    })`,
                  }}
                ></span>
              )}
              <div
                className="flex gap-2 align-items-center justify-content-between"
                style={{ width: "70vw" }}
              >
                {product.mediagallery[1] && (
                  <span
                    className="bg-primary bg-cover bg-no-repeat bg-right"
                    style={{
                      width: "50vw ",
                      height: "25vw ",
                      backgroundImage: `url(${
                        prefix + product.mediagallery[1].slug
                      })`,
                    }}
                  ></span>
                )}
                {product.mediagallery[2] && (
                  <span
                    className="bg-primary bg-cover bg-no-repeat bg-left"
                    style={{
                      width: "20vw ",
                      height: "25vw ",
                      backgroundImage: `url(${
                        prefix + product.mediagallery[2].slug
                      })`,
                    }}
                  ></span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div
      id="productsSection"
      className="w-full max-w-screen h-fit flex flex-column justify-content-start align-items-center md:align-items-start "
      // style={{ minHeight: "90vh" }}
    >
      {categoryWithProducts && (
        <Swiper
          className="w-full"
          onSwiper={(s) => setSwiper(s)}
          allowTouchMove={isMobile}
          autoHeight={true}
          slidesPerView={1}
        >
          <SwiperSlide
            className="w-screen flex flex-column justify-content-start  overflow-y- relative md:mt-7 h-min"
            style={{
              minHeight: isMobile
                ? `${50 * categoryWithProducts.length}svh`
                : "90vh",
            }}
          >
            <span
              className="flex flex-column align-items-center sticky top-0"
              style={{ backgroundColor: "var(--background)", zIndex: "100" }}
            >
              <h1 className="pr-3 pl-3 mb-3 text-center text-primary text-2xl md:text-5xl md:p-0">
                {"קטגוריות הייצור שלנו"}
              </h1>
              <span
                className="w-2  bg-primary"
                style={{ height: "3px" }}
              ></span>
            </span>
            <div className="w-full h-full mb-5 flex flex-column md:flex-row justify-content-center align-items-center p-2 md:p-5 md:pt-0 gap-3 align-self-center mt-5">
              {categoryWithProducts &&
                categoryWithProducts.map((category, index) => (
                  <a
                    href="#productsSlide"
                    className="col-10 md:col-4"
                    key={index}
                  >
                    <div
                      className="w-full"
                      style={{
                        maxWidth: isMobile ? "" : "300px",
                        minHeight: isMobile ? "50svh" : "",
                      }}
                      onClick={(e) => {
                        swiper.slideTo(1);
                        setSelectedCategoryIndex(index);
                        setSelectedProductIndex(0);
                      }}
                    >
                      <div
                        className="shadow-2 h-full flex flex-column  bg-white"
                        // style={{ borderRadius: "6px" }}
                      >
                        <div
                          className="bg-cover bg-no-repeat  w-full h-10rem md:h-10rem w-full relative flex align-items-end"
                          style={{
                            backgroundImage: `url(${prefix + category.image})`,
                          }}
                        >
                          <span className="w-full h-full md:h-5rem greyGradient absolute"></span>
                        </div>
                        <div className="w-full h-min p-3 flex flex-column">
                          <div className="text-900 font-medium text-xl h-2rem ">
                            {category.title}
                          </div>
                          <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                          <span className="text-center text-primary md:h-5rem flex">
                            {category.info}
                          </span>
                          <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                          <Button
                            label="מידע נוסף"
                            className="p-3 w-8 h-2rem md:w-full mt-auto align-self-center"
                            size={"small"}
                            onClick={(e) => {
                              swiper.slideTo(1);
                              setSelectedCategoryIndex(index);
                              setSelectedProductIndex(0);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              {isMobile && <span className="w-full h-3rem"></span>}
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="w-screen flex h-fit justify-content-end md:justify-content-start"
            style={{ maxWidth: "95vw" }}
            id="productsSlide"
          >
            <div className="w-11 md:w-fit h-full flex flex-column">
              <div className="flex align-items-center mt-3">
                {!isMobile && (
                  <Button
                    icon="pi pi-arrow-right"
                    text
                    onClick={(e) => {
                      swiper.slideTo(0);
                    }}
                    className=" h-fit"
                  />
                )}
                <span>
                  <h1 className="mb-0 mt-0 text-primary">
                    {categoryWithProducts &&
                      categoryWithProducts[selectedCategoryIndex].title}
                  </h1>
                  <hr className="w-full m-0 mt-0 border-top-2 border-bottom-none border-500" />
                </span>
              </div>
              <div
                className="w-full md:w-fit p-3 pl-1 justify-content-center align-items-start overflow-y-scroll"
                style={{ height: "70vh" }}
              >
                {categoryWithProducts &&
                  categoryWithProducts[selectedCategoryIndex].products.map(
                    (product, index) => {
                      return (
                        <ProductCard
                          product={product}
                          index={index}
                          key={index}
                        />
                      );
                    }
                  )}
              </div>
              {isMobile && <span className="w-full h-3rem"></span>}
            </div>

            {isMobile ? (
              <Button
                icon="pi pi-arrow-right"
                text
                onClick={(e) => {
                  swiper.slideTo(0);
                }}
                className=" h-fit absolute bottom-0 right-0"
              />
            ) : (
              <div className="w-full max-w-screen flex align-items-start">
                <div className="w-full h-full p-3 pt-0">
                  {categoryWithProducts && <SelectedProductCard />}
                </div>
              </div>
            )}
          </SwiperSlide>
        </Swiper>
      )}

      {dialogVisible && (
        <Dialog
          className="w-11 h-fit"
          visible={dialogVisible}
          onHide={() => {
            setDialogVisible(false);
          }}
          header={
            categoryWithProducts[selectedCategoryIndex].products[
              selectedProductIndex
            ].title
          }
          pt={{
            headerTitle: { dir: "rtl" },
            header: { dir: "ltr", className: "flex gap-2" },
            content: { dir: "rtl" },
            closeButton: {
              className: "absolute",
              style: { bottom: "10px", right: "10px" },
            },
          }}
          closeIcon="pi pi-arrow-right"
        >
          <SelectedProductCard />
        </Dialog>
      )}
      <span className="w-full h-3rem md:h-7rem mt-3 bg-primary"></span>
    </div>
  );
}
