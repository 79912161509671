import { Button } from "primereact/button";
export function HeroSection({ updateSwiperIndex }) {
  return (
    <div
      id="heroSection"
      className="w-full flex text-color p-5 justify-content-center align-items-center relative"
      style={{ minHeight: "80vh" }}
    >
      <div
        className="flex flex-column justify-content-center w-fit h-full "
        style={{ zIndex: 20 }}
      >
        <span className="max-w-screen w-fit align-self-center flex flex-column align-items-center">
          <span className="w-fit p-5 heroSpan">
            <h1
              className="m-0 text-center text-white md:w-10"
              style={{
                fontSize: window.innerWidth < 768 ? "3rem" : "10rem",
              }}
            >
              {"מתקפלים"}
            </h1>
            <h1 className="text-center heroSubtitle text-lg md:text-4xl mb-0">
              {"חברה ישראלית לייצור"}
            </h1>
            <h1 className="text-center mt-0 text-lg md:text-4xl text-white">
              {"רהיטי אלומיניום חכמים."}
            </h1>
          </span>

          {/* <Button
            label="גלו את עולם המוצרים   שלנו"
            outlined
            rounded
            size={window.innerWidth < 768 ? "small" : "large"}
            className="text-white relative mt-6 w-fit white-space-nowrap heroSpan"
            // pt={{
            //   root: {
            //     style: {
            //       background: "linear-gradient(to bottom, #f0f0f0, #d9d9d9)",
            //     },
            //   },
            // }}
            onClick={(e) => {
              const targetDiv = document.getElementById("productsSection");

              if (targetDiv) {
                targetDiv.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                // setCurrentSection("productsSection");
              }
            }}
          /> */}
        </span>
      </div>
      <div
        className="bg-primary absolute w-full h-full flex"
        style={{ zIndex: 0 }}
      >
        <div
          className="w-3 h-full relative bg-cover bg-no-repeat heroImage"
          style={{
            backgroundImage:
              "url(https://static.wixstatic.com/media/557f26_60fb006dc4784635adec98b5c3ccac44~mv2.jpg)",
          }}
        ></div>
        <div
          className="w-3 h-full relative bg-cover bg-no-repeat heroImage"
          style={{
            backgroundImage:
              "url(https://static.wixstatic.com/media/557f26_99aebf8aaf604fcfa26bfa5764d24eb0~mv2.jpg)",
          }}
        ></div>
        <div
          className="w-3 h-full relative bg-cover bg-no-repeat heroImage"
          style={{
            backgroundImage:
              "url(https://static.wixstatic.com/media/557f26_291b9907a7dc4a58b23f00196b620fe6~mv2.jpg)",
          }}
        ></div>
        <div
          className="w-3 h-full relative bg-cover bg-no-repeat heroImage"
          style={{
            backgroundImage:
              "url(https://static.wixstatic.com/media/557f26_26884addc2a0460392e16c5a60e37fd5~mv2.jpg)",
          }}
        ></div>
      </div>
    </div>
  );
}
