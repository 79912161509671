import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { SpeedDial } from "primereact/speeddial";
import { useState } from "react";
import { HeroSection } from "./components/HeroSection";
import { ValueProposition } from "./components/ValueProposition-v32";
import { Products } from "./components/Products";
import { ContactSection } from "./components/ContactSection";
import { Gallery } from "./components/Gallery";
import headerLogo from "./assets/headerLogo.png";
import { Dialog } from "primereact/dialog";
import { AboutUs } from "./components/AboutUs";
export const isMobile = window.innerWidth < 900;
function StartMenu() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const buttons = () => {
    return (
      <>
        <a href="#aboutUs">
          <Button
            label="מי אנחנו"
            text
            onClick={(e) => {
              setSidebarVisible(false);
            }}
          />
        </a>
        <a href="#valuePropositionSection">
          <Button
            label="הפיתוח"
            text
            onClick={(e) => {
              setSidebarVisible(false);
            }}
          />
        </a>

        <a href="#productsSection">
          <Button
            label="המוצרים"
            text
            onClick={(e) => {
              setSidebarVisible(false);
            }}
          />
        </a>
        <a href="#gallerySection">
          <Button
            label="גלריה"
            text
            onClick={(e) => {
              setSidebarVisible(false);
            }}
          />
        </a>
        <a href="#contactSection">
          <Button
            label="צרו קשר"
            text
            onClick={(e) => {
              setSidebarVisible(false);
            }}
          />
        </a>
      </>
    );
  };
  return (
    <div className="flex w-1 md:w-7">
      {isMobile ? (
        <>
          <Button
            icon="fi fi-br-menu-burger"
            text
            size="large"
            onClick={() => {
              setSidebarVisible(true);
            }}
          />
          <Sidebar
            visible={sidebarVisible}
            position="right"
            onHide={() => setSidebarVisible(false)}
            className="w-13rem md:w-20rem lg:w-30rem"
          >
            <div className="flex flex-column">{buttons()}</div>
          </Sidebar>
        </>
      ) : (
        <div className="w-full">{buttons()}</div>
      )}
    </div>
  );
}

const speedDialerItems = [
  {
    label: "WhatsApp",
    icon: "fi fi-brands-whatsapp",
    command: () => {
      window.open(
        "https://api.whatsapp.com/send?phone=972527141185&text=היי%2C%20אשמח%20לשמוע%20פרטים%20על%20המוצר",
        "_blank"
      );
    },
  },
  {
    label: "Phone",
    icon: "fi fi-rr-phone-call",
    command: () => {},
  },
  {
    label: "Mail",
    icon: "fi fi-rr-envelope",
    command: () => {},
  },
];
function Footer() {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <footer
      className="w-full flex flex-column align-items-center justify-content-center bg-primary gap-2 p-3"
      style={{ minHeight: "10vh" }}
    >
      <span className="w-full text-white flex align-items-center justify-content-center gap-3">
        <a className="text-white" href="tel://+9727141185">
          052-714-1185
        </a>
        <a
          className="text-white"
          href="mailto:sales@mitkaplim.co.il?subject=התעניינות בקניית מוצרי מתקפלים"
        >
          sales@mitkaplim.co.il
        </a>
      </span>
      <span className="w-full text-white flex align-items-center justify-content-center gap-3">
        <span
          className="underline cursor-pointer"
          onClick={(e) => setDialogVisible(true)}
        >
          הצהרת נגישות
        </span>
      </span>
      <Dialog
        className="md:w-10 w-11"
        visible={dialogVisible}
        onHide={(e) => setDialogVisible(false)}
      >
        <div className="w-full flex flex-column" style={{ direction: "rtl" }}>
          <h1>הצהרת נגישות מתקפלים</h1>
          <br />
          <br />
          עדכון: דצמבר 2023.
          <br />
          <br />
          <h2>כללי</h2>
          אנו במתקפלים שואפים להבטיח שהשירותים שלנו יהיו נגישים לכל האנשים. על
          מנת להשיג זאת, השקענו משאבים חשובים באתר שלנו כדי שיהיה זמין לאנשים עם
          מוגבלויות. אנו באמת מאמינים שמאמצי הנגשת אתרים הם חיוניים כדי להבטיח
          שלכל המשתמשים מגיעה הזכות לעצמאות, שוויון וקלות שימוש.
          <h2>נגישות ב-mitkaplim.co.il</h2>
          באמצעות כלי הביקורת של <a href="checker.org">checker.org</a> של
          נגישות, mitkaplim.co.il נסרק לתאימות בהתאם להנחיות הנגישות לתוכן
          אינטרנט (WCAG 2.1). מאז ביצוע הביקורת, מתקפלים ממשיכה להעלות את תקני
          הנגישות שלה, [נא להוסיף את המשפט הבא רק אם אתה משתמש בווידג'ט] ומשתמשת
          כעת ביישומון נגישות לאינטרנט, תוכנה המאפשרת למשתמשים עם מוגבלויות לגשת
          באופן שווה לאתר של mitkaplim.co.il .<h2>כתב ויתור</h2>
          אנו במתקפלים מחויבים לעדכן ולשפר באופן מתמיד את נגישות האתר והשירותים.
          אנו מאמינים שזו אחריותנו ומחויבותנו המוסרית ההדדית לספק גישה לכל
          המשתמשים. מכיוון שאנו מבקשים להתאים ולשפר באופן מתמיד את בעיות הנגישות
          באתר זה, אנו גם מתחייבים לסרוק באופן קבוע את mitkaplim.co.il עם הסורק
          של בודק הנגישות. המטרה שלנו היא לזהות ולתקן כל מחסום נגישות באתר שלנו.
          למרות שאנו שואפים להבטיח שכל הדפים והתכנים ב-mitkaplim.co.il יהיו
          נגישים באופן מלא, ייתכן שחלק מתוכן האתר טרם הותאם במלואו כדי לעמוד
          בתקני הנגישות המחמירים ביותר. מסיבה זו, אנחנו כאן בשבילכם. אם יש לך
          בעיות בגישה לתוכן או בשימוש ב-mitkaplim.co.il, או אם תזדקק לסיוע עם
          חלק כלשהו באתר, אנא אל תהסס ליידע אותנו. אתם מוזמנים ליצור איתנו קשר
          בשעות העבודה הרגילות, ונשמח לעזור. אנא עיין בפרטי ההתקשרות למטה.
          <h2>צור קשר</h2>
          יש לך שאלות, זקוק לסיוע או רוצה לדווח על בעיית נגישות? נא ליצור קשר עם
          תמיכת הלקוחות של תקפלים:
          <br />
          <br />
          אימייל:{" "}
          <a href="mailto:sales@mitkaplim.co.il">sales@mitkaplim.co.il</a>
          טלפון: 052-714-1185
        </div>
      </Dialog>
    </footer>
  );
}
const bullets = [
  "משקל נמוך",
  "בלתי מחליד",
  "פתיחה מהירה",
  "סגירה קלה",
  "יציבות מקסימלית",
  "אחסון פרקטי",
  "מיטה מתקפלת",
  "מיטת אירוח",
  "מיטה קלה",
  "שולחן אלומיניום",
  "ריהוט חכם",
  "נעילה חכמה",
  "אחסון יעיל",
];
function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

function getBulletsArr() {
  const bulletsRepeated = bullets.flatMap((bullet) =>
    Array(window.innerWidth < 768 ? 7 : 7).fill(bullet)
  );
  return shuffleArray(bulletsRepeated);
}

const BulletScroll = () => {
  return (
    <div
      className="bullet-scroll-container w-full right-0 bg-white absolute"
      style={{ top: "9vh", zIndex: "1001" }}
    >
      <div className="bullet-scroll">
        {getBulletsArr().map((bullet, index) => (
          <span key={index} className="bullet-item">
            {bullet}
          </span>
        ))}
      </div>
    </div>
  );
};
function App() {
  return (
    <div
      className="w-full h-screen overflow-hidden flex flex-column justify-content-start align-items-center"
      style={{ backgroundColor: "#FFFAFA", direction: "rtl" }}
    >
      <div
        className="w-screen bg-white flex justify-content-between md:justify-content-start gap-3 align-items-center p-1 md:p-3 flex-row-reverse md:flex-row "
        style={{ height: "10vh", maxHeight: "15rem" }}
      >
        <a href="https://www.mitkaplim.co.il">
          <img src={headerLogo} alt="headerLogo" height={"60px"} />
        </a>
        <StartMenu />
        {/* <EndMenu /> */}
        <BulletScroll />
      </div>

      <div
        className="w-screen overflow-y-scroll overflow-hidden flex flex-column align-items-center relative"
        style={{
          minHeight: "90vh",
          // height: "90vh",
        }}
      >
        <HeroSection />
        <AboutUs />
        <span className="w-full sm:w-11 sm:align-self-end flex flex-column">
          <h1 className="m-3 mt-5 text-right w-11 align-self-center text-primary">
            הפיתוח שלנו
          </h1>
        </span>
        <ValueProposition />
        <Products />
        <Gallery />
        <ContactSection />
        <Footer />
      </div>

      {!isMobile && (
        <SpeedDial
          model={speedDialerItems}
          radius={80}
          type="quarter-circle"
          direction="up-right"
          style={
            isMobile
              ? { left: 10, top: 200, zIndex: 100 }
              : { left: 15, bottom: 15 }
          }
          buttonClassName="p-button-info"
          showIcon={"fi fi-br-messages text-xl"}
          rotateAnimation={false}
          pt={{
            action: {
              style: { backgroundColor: "orange " },
              className: "text-xl",
            },
            button: {
              root: { style: { maxHeight: "15vw", maxWidth: "15vw" } },
            },
          }}
        />
      )}
    </div>
  );
}

export default App;
