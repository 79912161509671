import { useState, useRef, useEffect } from "react";
import autoAnimate from "@formkit/auto-animate";
import { isMobile } from "../App";
export function AboutUs() {
  const [show, setShow] = useState(false);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const reveal = () => setShow(!show);
  return (
    <div id="aboutUs" className="w-full flex flex-column">
      <span
        className="w-full sm:w-11 sm:align-self-end flex flex-column"
        ref={parent}
      >
        <h1 className="m-0 mt-5 text-right w-11 align-self-center text-primary">
          מי אנחנו
        </h1>

        <p className="m-3 mt-3 mx-4 md:mr-8 text-right md:w-8 align-self- text-primary font-bold">
          חברת מתקפלים היא חברה ישראלית שהוקמה על ידי צוות מתכנני מוצר ומהנדסים
          מנוסים, כשלנגד עיניהם מטרה: לפתח ולייצר רהיטי אלומיניום מתקפלים --
          באיכות ובנוחות הטובים ביותר.
        </p>
        {show && (
          <span className="m-3 mt-0 mx-4 md:mr-8 text-right md:w-8 align-self- text-primary">
            <p>הצוות הציב לעצמו מספר אילוצי פיתוח:</p>
            <ol>
              <li>גלם אלומיניום - קל ובלתי מחליד.</li>
              <li>פתיחת וסגירת רגליים &ndash; בפעולה אחת פשוטה.</li>
              <li>יציבות מקסימלית - כמו רהיט קבוע. .</li>
              <li>פרקטיקה באחסנה ושינוע.</li>
            </ol>
            <p>
              לאחר שנות פיתוח והשקעה רבה, הם הגשימו את חזונם והקימו בישראל את
              המפעל הראשון לייצור רהיטי אלומיניום מתקפלים ו... חכמים!
            </p>
            <p>
              כיום המפעל מייצר את מוצריו ובמקביל - צוות הפיתוח עמל על פיתוח
              המוצר מתקפל הבא.
            </p>
          </span>
        )}
        <span className="underline mr-4 md:mr-8" onClick={reveal}>
          {show ? "הסתר" : "קרא עוד"}
        </span>

        {!isMobile && (
          <span
            className="w-11 md:mr-6 bg-primary mb-5 mt-5"
            style={{ height: "2px" }}
          ></span>
        )}
      </span>
    </div>
  );
}
