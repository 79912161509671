import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useState, useRef } from "react";
const WhatsappForm = ({ successMessage }) => {
  return (
    <div
      id="whatsappForm"
      key="whatsappForm"
      className="h-5rem sm:h-7rem w-full flex py-3 align-items-center"
    >
      <InputMask
        className="h-full w-10 border-noround sm:text-5xl"
        keyfilter={"num"}
        placeholder="XXX-XXX-XXXX"
        id="userPhoneNumberWhatsapp"
        key="userPhoneNumberWhatsapp"
        mask="999-999-9999"
      />
      <a
        href="#contactSection"
        className="h-full w-2"
        onClick={() => {
          whatsappLink().then((res) => {
            if (res) successMessage();
          });
        }}
      >
        <Button
          className="h-full w-full border-noround sm:text-4xl"
          icon="fi fi-brands-whatsapp "
          severity="warning"
        />
      </a>
    </div>
  );
};
const PhoneForm = ({ successMessage }) => {
  return (
    <div
      id="phoneForm"
      className="h-5rem sm:h-7rem w-full flex py-3 align-items-center"
      key="phoneForm"
    >
      <InputMask
        className="h-full w-10 border-noround sm:text-5xl"
        keyfilter={"num"}
        placeholder="XXX-XXX-XXXX"
        id="userPhoneNumber"
        key="userPhoneNumber"
        mask="999-999-9999"
      />
      <a
        href="#contactSection"
        className="h-full w-2"
        onClick={() => {
          callLinkPhone().then((res) => {
            if (res) successMessage();
          });
        }}
      >
        <Button
          className="h-full w-full border-noround sm:text-4xl"
          icon="fi fi-rr-phone-call"
          severity="warning"
        />
      </a>
    </div>
  );
};
const EmailForm = ({ successMessage }) => {
  return (
    <div>
      <div
        id="emailForm"
        className="h-5rem sm:h-7rem w-full flex py-3 align-items-center relative"
        key="emailForm"
      >
        <InputText
          className="h-full w-10 border-noround sm:text-5xl"
          keyfilter={"email"}
          placeholder="XXX@YYY.ZZZ"
          id="userEmailAddress"
          key="userEmailAddress"
        />
        <a
          href="#contactSection"
          className="h-full w-2"
          onClick={() => {
            sendMail().then((res) => {
              if (res) successMessage();
            });
          }}
        >
          <Button
            className="h-full w-full border-noround sm:text-4xl"
            icon="fi fi-rr-envelope"
            severity="warning"
          />
        </a>
      </div>
      <div className=" ml-1 md:ml-3 grid gap-3 w-full">
        <span
          className="underline "
          onClick={(e) => {
            const emailInput = document.getElementById("userEmailAddress");
            if (emailInput) {
              emailInput.value += "@gmail.com";
            }
          }}
        >
          @gmail.com
        </span>
        <span
          className="underline "
          onClick={(e) => {
            const emailInput = document.getElementById("userEmailAddress");
            if (emailInput) {
              emailInput.value += "@walla.com";
            }
          }}
        >
          @walla.com
        </span>

        <span
          className="underline "
          onClick={(e) => {
            const emailInput = document.getElementById("userEmailAddress");
            if (emailInput) {
              emailInput.value += "@hotmail.com";
            }
          }}
        >
          @hotmail.com
        </span>
      </div>
    </div>
  );
};

export function ContactSection() {
  const [selectedForm, setSelectedForm] = useState("טלפון");
  const toast = useRef(null);

  const successMessage = () => {
    toast.current.show({
      severity: "success",
      summary: "הודעה נשלחה בהצלחה",
      // detail: "Message Content",
    });
  };
  const formSwitcher = () => {
    switch (selectedForm) {
      case "טלפון":
        return PhoneForm({ successMessage });
      case "וואטסאפ":
        return WhatsappForm({ successMessage });
      case "מייל":
        return EmailForm({ successMessage });
      default:
        return PhoneForm({ successMessage });
    }
  };
  return (
    <div
      id="contactSection"
      className="w-full h-full flex flex-column p-5 pb-7 justify-content-start align-items-center relative"
      style={{ minHeight: "80vh" }}
    >
      <Toast ref={toast} position="bottom-center" />
      <div className="w-12 md:w-10 h-full p-1 md:p-3 pb-7 bg-primary flex flex-column align-items-center justify-content-center">
        <h1 className=" text-center text-white w-11 md:w-full mb-3 text-xl md:text-4xl">
          {"השאירו פרטים ונחזור אליכם בהקדם:"}
        </h1>
        <div
          className="w-12 md:w-6 p-3 flex flex-column justify-content-center align-items-center"
          style={{ direction: "ltr" }}
        >
          <SelectButton
            value={selectedForm}
            options={[
              { label: "מייל", value: "מייל" },
              { label: "וואטסאפ", value: "וואטסאפ" },
              { label: "טלפון", value: "טלפון" },
            ]}
            onChange={(e) => {
              setSelectedForm(e.value);
            }}
            style={{ direction: "ltr" }}
            className="w-full flex justify-content-center "
            pt={{
              button: {
                className: " w-6 text-sm p-2 md:p-3 border-noround",
              },
            }}
          />
          {formSwitcher()}
        </div>
      </div>
    </div>
  );
}
function sendMail() {
  const userEmail = document.getElementById("userEmailAddress").value;
  if (userEmail.length <= 0) return;
  var formdata = new FormData();
  formdata.append("mail", userEmail);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    "https://hook.eu2.make.com/22jgltyptrxtapdppvzooheh1ahyo7x7",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return true;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}

function callLinkPhone() {
  const userPhoneNumber = document
    .getElementById("userPhoneNumber")
    .value.replace("-", "")
    .replace("-", "");
  if (userPhoneNumber.length !== 10) return;

  var formdata = new FormData();
  formdata.append(
    "phone",
    `<html><a href="tel:${userPhoneNumber.replace(
      "0",
      "+972"
    )}"> ${userPhoneNumber}</a> טלפון`
  );

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  return fetch(
    "https://hook.eu2.make.com/ghm7f7ahdobp9a4sdb1n15kaetgnad53",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return true;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}

function whatsappLink() {
  const userPhoneNumber = document
    .getElementById("userPhoneNumberWhatsapp")
    .value.replace("-", "")
    .replace("-", "");
  if (userPhoneNumber.length !== 10) return;

  var formdata = new FormData();
  formdata.append(
    "phone",
    `https://wa.me/${userPhoneNumber.replace(
      "0",
      "+972"
    )} ${userPhoneNumber} וואטסאפ`
  );

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  return fetch(
    "https://hook.eu2.make.com/ghm7f7ahdobp9a4sdb1n15kaetgnad53",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return true;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}
