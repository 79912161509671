import { useEffect, useState } from "react";
import screenshot_table from "../assets/screenshot_table.jpg";
import scrollGesture from "../assets/scrollGesture.gif";
const prefix = "https://static.wixstatic.com/media/";
const isMobile = window.innerWidth < 768;
const diameter = isMobile ? "200px" : "250px";

export function ValueProposition() {
  const [values, setValues] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://yonivas0.editorx.io/mitkaplimbackend/_functions/getValues"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // If you expect JSON response, use response.json()
        // If you expect other response types, adjust accordingly
        const data = await response.json();
        setValues(data.message);
        // console.log("data.message", data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    values && (
      <div
        className="w-full md:w-11 md:align-self-end relative sm:flex sm:flex-column md:align-items-center overflow-auto"
        style={{
          minHeight: isMobile ? "80svh" : "90vh ",
          display: "grid",
          gridTemplateColumns: `repeat(${values.length},1fr)`,
        }}
        id="valuePropositionSection"
      >
        {values.map((value, index) => (
          <div
            key={index}
            className="w-full sticky flex flex-column justify-content-start align-items-center pb-5"
            style={{
              minHeight: isMobile ? "1svh" : "82svh",
              minWidth: isMobile ? "90vw" : "",
              top: isMobile ? "0px" : `${24 * index}px`,
            }}
          >
            <div
              className="w-11 bg-primary h-full md:h-min flex flex-column p-1  shadow-5 md:shadow-7"
              style={{ minHeight: "70svh" }}
            >
              <span className="w-full">{value?.label}</span>
              <div className="w-full h-fit py-2 px-1 md:px-7 grid mt-3 md:mt-5 gap-3 align-items-start justify-content-center md:justify-content-start">
                <img
                  alt="value pic"
                  style={{ height: diameter, width: diameter }}
                  loading="eager"
                  className="border-circle border-3"
                  src={
                    value.image ? `${prefix + value.image}` : screenshot_table
                  }
                />
                <div className="mr-5 w-full md:w-6">
                  <h1 className=" text-5xl md:text-7xl m-0 mb-3 w-full text-right">
                    {value?.label}
                  </h1>
                  <span className="list-none w-full">
                    {value.pros &&
                      value.pros.split("|").map((pro, index) => (
                        <li
                          className="flex gap-2 w-full mb-3 text-lg"
                          key={index}
                        >
                          <span>✅ {pro}</span>
                        </li>
                      ))}
                  </span>
                </div>
              </div>
            </div>
            {index === 0 && !isMobile && (
              <img
                alt="scroll gesture"
                src={scrollGesture}
                className="absolute"
                style={{ bottom: "200px", height: "50px" }}
              />
            )}
          </div>
        ))}
      </div>
    )
  );
}
