import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";
const prefix = "https://static.wixstatic.com/media/";
export function Gallery() {
  const [galleryImages, setGalleryImages] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://yonivas0.editorx.io/mitkaplimbackend/_functions/getImages"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // If you expect JSON response, use response.json()
        // If you expect other response types, adjust accordingly
        const data = await response.json();
        setGalleryImages(data.message);
        // console.log("data.message", data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div
      id="gallerySection"
      className="flex flex-column p-5 justify-content-center align-items-center relative"
      style={{ minHeight: "90vh" }}
    >
      <h1 className="mb-3 text-center text-primary text-2xl md:text-5xl">
        {"תמונות ששוות אלף מילים"}
      </h1>
      <span className="w-2 bg-primary mb-7" style={{ height: "3px" }}></span>

      <Swiper
        // className="text-white"
        spaceBetween={30}
        slidesPerGroupSkip={1}
        slidesPerView={1}
        breakpoints={{
          769: {
            slidesPerView: 4,
            slidesPerGroupSkip: 4,
          },
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation
        modules={[Navigation, Autoplay]}
        style={{ width: "80vw" }}
      >
        {galleryImages &&
          galleryImages.map((image, index) => {
            return (
              <SwiperSlide
                className="bg-cover md:bg-cover bg-center bg-no-repeat flex justify-content-center "
                style={{
                  backgroundImage: `url(${prefix + image.fileName})`,
                  minHeight: window.innerWidth < 768 ? "130vw" : "30rem",
                }}
                key={index}
              >
                <div className="w-full h-7rem md:h-5rem blackGradient align-self-end text-white flex justify-content-center align-items-center gap-1 ">
                  <span className="font-light h-fit w-min white-space-nowrap">
                    {image.originalFileName}
                  </span>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
